<template>
  <div style="background-color: #f8fcff;">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>报废资料</span>
      </div>
      <div class="middle">
        <div class="model-body">
          <p class="model-title">个人车主所需材料</p>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">1</span>机动车行驶证原件</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoleft1.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"> <span class="sp1">2</span>车辆登记证书原件</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoleft2.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">3</span>车主身份证复印件</p>
              <p class="p2">*身份证复印件需身份证原件直接复印，水印清晰，四边清晰，不能手写文字，可以盖“再次复印无效”条形章</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoright2.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">4</span>机动车行驶证原件</p>
              <p class="p2">*车架号，发动机号需与车辆登记时相符</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoleft4.png" alt="">
            </div>
          </div>
          <p class="model-bottom">注：关于违章，本地车牌（陕A/陕U），不保留车牌不需 消违章，异地车均需消违章</p>
        </div>
        <div class="model-body">
          <p class="model-title">公户车所需材料</p>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">1</span>组织机构代码证复印件（加盖公章）</p>
              <p class="p2">*名称需与车辆登记所有人相符，单位名称 有变更需提供变更文件</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoright1.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"> <span class="sp1">2</span>经办人身份证复印件</p>
              <p class="p2">*身份证复印件需身份证原件直接复印，水印清晰，四边清晰，不能手写文字，可以盖“再次复印无效”条形章</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoright2.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">3</span>机动车行驶证原件</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoleft1.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">4</span>车辆登记证书原件</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoleft2.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">5</span>车辆及车牌</p>
              <p class="p2">*车架号，发动机号需与车辆登记时相符</p>
            </div>
            <div class="right">
              <img src="/img/ziliaoleft4.png" alt="">
            </div>
          </div>
          <div class="userData">
            <div class="left">
              <p class="p1"><span class="sp1">6</span>打印下方五张表手动填写，且均需要加盖公章：</p>
              <p class="p2" style="color: #C91C25;">
                <span class="spBox">
                  <span>《注销申请表》</span>
                  <a href="/file/shenqing.docx" download="注销申请表.docx">下载</a>
                </span>
                <span class="spBox">
                  <span>《委托书》</span>
                  <a href="/file/weituo.docx" download="委托书.docx">下载</a>
                </span>
                <span class="spBox">
                  <span>《告知书》</span>
                  <a href="/file/gaozhishu.doc" download="告知书.doc">下载</a>
                </span>
                <span class="spBox">
                  <span>《承诺书》</span>
                  <a href="/file/ruchang.docx" download="承诺书.docx">下载</a>
                </span>
                <span class="spBox">
                  <span>《情况说明》</span>
                  <a href="/file/qingkuang.docx" download="情况说明.docx">下载</a>
                </span>
              </p>
            </div>
            <div class="right">
              <img src="/img/ziliaoright3.png" alt="">
            </div>
          </div>
          <p class="model-bottom">注：关于违章，本地车牌（陕A/陕U），不保留车牌不需 消违章，异地车均需消违章</p>
          <div>
            <el-button class="bottom-btn" @click="handleGoBack">返回填写信息</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "adressMaintain",
  data() {
    return {};
  },
  methods: {
    handleGoBack() {
      this.$router.push({
        path: "/scrap/firstScrap",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 62.5%;
  margin: 20px auto; /* no */
}
.middle {
  display: flex;
  justify-content: space-between;
  padding: 0 2.5% 0 2.5%;
}
.clearfix {
  font-size: 18px; /* no */
}
.model-body {
  width: 47.08%;
}
.model-title {
  background: #f8fcff;
  text-align: center;
  height: 44px; /* no */
  line-height: 44px; /* no */
}
.model-bottom {
  color: #666666;
  padding: 0 0 0 20px; /* no */
}
.userData {
  display: flex;
  justify-content: space-between;
  padding: 20px; /* no */
  .left {
    width: 343px; /* no */

    .p1 {
      margin-bottom: 10px; /* no */
    }

    .p2 {
      line-height: 30px; /* no */
      color: #666666;
      margin-left: 30px; /* no */
    }

    .sp1 {
      background: linear-gradient(0deg, #181a7d, #30c9cd, #5be196);
      display: inline-block;
      width: 24px; /* no */
      height: 24px; /* no */
      border-radius: 50%;
      color: white;
      text-align: center;
      line-height: 24px; /* no */
      margin-right: 10px; /* no */
    }

    .spBox {
      display: flex;
      justify-content: space-between;
      padding-right: 60px; /* no */
      a {
        color: #1dac9d;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
.bottom-btn {
  margin-top: 20px; /* no */
  transform: translateX(-50%);
  background: #24a2aa;
  color: white;
}
</style>
